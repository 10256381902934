import '@popsure/dirty-swan/dist/index.css';
import 'css/app.scss';
import 'css/index.scss';
import 'css/animation.scss';
import '@getpopsure/cookie-consent/dist/style.css';

import { CookieBanner } from '@getpopsure/cookie-consent';
import { setCookieLocale, setCookieRegion } from '@getpopsure/i18n-react';
import { socialMedia, website } from '@getpopsure/private-constants';
import {
  initialize as initializeUtmTracker,
  populateTrackingObjectFromUrl,
} from '@getpopsure/tracker';
import { GoogleTagManager } from '@next/third-parties/google';
import Footer from 'components/footer';
import { setupIntercomConfiguration } from 'components/IntercomLoaderWrapper/utils/setupIntercomConfiguration';
import NavigationBar from 'components/navigationBar';
import PrismicPreviewWrapper from 'components/PrismicPreviewWrapper';
import { FEATHER_DOMAIN } from 'constants/cookies';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { useClient } from 'hooks/useClient';
import { useNavOffset } from 'hooks/useNavOffset';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config.js';
import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import { createPrismicClient } from 'prismicio';
import React, { useEffect, useState } from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { handleFinanceAdsCookie } from 'services/financeAds';
import { useTracker } from 'services/tracker';
import { fetchOne } from 'strapi/utils/api';
import { canonicalUrlForRouter } from 'util/canonicalUrlForRoute';
import { isDevelopment, isProduction } from 'util/env';
import { getLanguageFromLocaleIdentifier } from 'util/getCountryFromLocaleIdentifier';
import {
  getFooterFromPrismicNavigationData,
  getFooterFromStrapiNavigationData,
  getMenuFromPrismicNavigationData,
  getMenuFromStrapiNavigationData,
} from 'util/getMenuFromCmsNavigationData';
import {
  getLocaleCookieFromLocaleIdentifier,
  getRegionCookieFromLocaleIdentifier,
} from 'util/getRedirectPathFromLocaleCookie';
import { parseNestedPath } from 'util/prismicPathResolver';

import { setTrackersAfterConsent } from '../services/tracker/setTrackersAfterConsent';

declare const AOS: {
  init: () => void;
  refresh: () => void;
  refreshHard: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomAppProps<P = any> = {
  pageProps: P;
  mainNav: NavigationProductCategory[];
  footer: FooterProductsMenu;
  cmsUidsEn: Array<string>;
  cmsUidsDe: Array<string>;
  isHiring: boolean;
  isPreview: boolean;
} & Omit<AppProps<P>, 'pageProps'>;

/**
 * Initialize the cookie tracker
 * to allow debugging of utm params on dev environment
 */
const cookieTrackerOptions = isDevelopment
  ? { cookieAttributes: { expires: 30, domain: 'localhost' } }
  : {};

initializeUtmTracker(cookieTrackerOptions);

const MyApp = ({
  Component,
  pageProps,
  mainNav,
  footer,
  cmsUidsDe,
  isHiring,
}: CustomAppProps) => {
  const router = useRouter();
  const canonicalUrl = canonicalUrlForRouter(router);

  const language = getLanguageFromLocaleIdentifier(router.locale);

  const hasMounted = useClient();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const setLocaleCookie = () => {
      const currentLocale = router.locale || 'en-de';

      setCookieLocale(
        getLocaleCookieFromLocaleIdentifier(currentLocale),
        FEATHER_DOMAIN
      );

      // set the region cookie.
      // for now this is hardcoded for testing purposes.
      // we will move the logic to the appropriate library and import that functionality.
      const region = getRegionCookieFromLocaleIdentifier(currentLocale);
      setCookieRegion(region, FEATHER_DOMAIN);
    };

    setLocaleCookie();
  }, [router.locale]);

  setupIntercomConfiguration();

  useTracker();

  useEffect(() => {
    if (hasMounted) {
      handleFinanceAdsCookie();
      populateTrackingObjectFromUrl();
    }
  }, [hasMounted]);

  useEffect(() => {
    const refreshAOS = () => typeof AOS !== 'undefined' && AOS?.refreshHard?.();

    router.events.on('routeChangeComplete', refreshAOS);

    return () => {
      router.events.off('routeChangeComplete', refreshAOS);
    };
  }, [router.events]);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrolled) {
        setScrolled(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  const { navOffset, allRefs } = useNavOffset();

  const cmsPathsDe = cmsUidsDe.map(
    (uid) => `/${parseNestedPath(uid as string)}`
  );

  return (
    <>
      <GoogleTagManager gtmId="GTM-TB855HJ" />
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        {scrolled && (
          <link
            rel="stylesheet"
            href="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.css"
          />
        )}
      </Head>
      <Script
        src="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.js"
        strategy="lazyOnload"
        onReady={() => {
          AOS.init();
        }}
      />
      <DefaultSeo
        canonical={canonicalUrl}
        titleTemplate="%s | Feather"
        dangerouslySetAllPagesToNoFollow={!isProduction}
        dangerouslySetAllPagesToNoIndex={!isProduction}
        openGraph={{
          title: 'Feather',
          description:
            'For all your German insurance needs online and in English',
          url: canonicalUrl,
          type: 'website',
          images: [
            {
              url: `${website.base}/open-graph-${language ?? 'en'}.jpg`,

              width: 1200,
              height: 630,
              alt: 'Composition with “Honest, simple insurance” written on the right, two persons looking at a laptop on a kitchen counter on the left.',
            },
            {
              url: `${website.base}/open-graph-en-2.jpg`,
              width: 1200,
              height: 630,
              alt: 'Composition with "Honest, simple insurance" written on the right hand side, two women sitting on the stairs looking at the Feather app on their phone on the left hand side.',
            },
          ],
        }}
      />
      <OrganizationJsonLd
        type="Organization"
        name="Feather Insurance"
        slogan="Honest, simple insurance."
        legalName="Popsure Deutschland GmbH"
        logo={`${website.base}/logo-112x112.png`}
        sameAs={[
          socialMedia.facebook,
          socialMedia.instagram,
          socialMedia.linkedIn,
        ]}
        url={website.base}
        id={`${website.base}#organization`}
      />
      <LiveChatLoaderProvider
        provider="intercom"
        providerKey={process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID ?? ''}
      >
        <PrismicPreviewWrapper>
          <NavigationBar
            allRefs={allRefs}
            key={router.asPath}
            menu={mainNav}
            isHiring={isHiring}
          />
          <Component
            navOffset={navOffset}
            germanPaths={cmsPathsDe} // for homepage
            {...pageProps}
          />
          <Footer
            condensed={pageProps?.condensedFooter}
            productsMenu={footer}
          />
          <CookieBanner
            onSave={setTrackersAfterConsent}
            allowScrollOnPaths={['/privacy-policy', '/impressum']}
          />
        </PrismicPreviewWrapper>
      </LiveChatLoaderProvider>
    </>
  );
};

MyApp.getInitialProps = async ({ router }: AppContext) => {
  const locale = router.locale || 'en-de';

  let mainNav = [];
  let footer = {};
  const prismicClient = createPrismicClient();

  const mainNavStrapi = await fetchOne('navigations', {
    uid: 'main-navigation',
    locale,
  });

  if (mainNavStrapi) {
    mainNav = getMenuFromStrapiNavigationData(mainNavStrapi);
  } else {
    const mainNavPrismic = await prismicClient.getByUID(
      'navigation',
      'main-navigation',
      { lang: locale }
    );
    mainNav = getMenuFromPrismicNavigationData(mainNavPrismic);
  }

  const footerStrapi = await fetchOne('navigations', {
    uid: 'footer',
    locale,
  });

  if (footerStrapi) {
    footer = getFooterFromStrapiNavigationData(footerStrapi);
  } else {
    const footerPrismic = await prismicClient.getByUID('navigation', 'footer', {
      lang: locale,
    });
    footer = getFooterFromPrismicNavigationData(footerPrismic);
  }

  const enPages = await prismicClient.getAllByType('page', {
    lang: ENGLISH_LOCALE_IDENTIFIER,
  });
  const cmsUidsEn = enPages.map((page) => page.uid);
  const dePages = await prismicClient.getAllByType('page', {
    lang: GERMAN_LOCALE_IDENTIFIER,
  });
  const cmsUidsDe = dePages.map((page) => page.uid);

  const jobs = (await prismicClient.getAllByType('job')).map((job) => job.data);
  const isHiring = jobs.length > 0;

  const isPreview = !!router.isPreview;

  return {
    mainNav,
    footer,
    cmsUidsEn,
    cmsUidsDe,
    isHiring,
    isPreview,
  };
};

export default appWithTranslation(MyApp, nextI18NextConfig);
