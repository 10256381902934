import { STRAPI_LOCALES } from 'constants/i18n';
import { PRISMIC_UID_SEPARATOR } from 'constants/prismic';
import { STRAPI_UID_SEPARATOR } from 'constants/strapi';
import { APIResponseData, GetValues } from 'strapi/types/utils';
import Strapi, { StrapiLocale } from 'strapi-sdk-js';

type ResponseTypeMapping = {
  pages: 'api::page.page';
  navigations: 'api::navigation.navigation';
  homepages: 'api::homepage.homepage';
  tables: 'api::table.table';
};

export type CollectionType = keyof ResponseTypeMapping;

export type GetResponseType<K extends CollectionType> = ResponseTypeMapping[K];

const createStrapiClient = () => {
  return new Strapi({
    url: process.env.STRAPI_API_URL,
    axiosOptions: {
      headers: { Authorization: `Bearer ${process.env.STRAPI_TOKEN}` },
    },
  });
};

export const fetchMany = async <T extends CollectionType>(
  collection: T,
  {
    locale,
    strapiClient,
  }: {
    locale: string;
    strapiClient?: Strapi;
  }
): Promise<GetValues<GetResponseType<T>>[]> => {
  const strapi = strapiClient ?? createStrapiClient();

  const result = await strapi.find<APIResponseData<GetResponseType<T>>[]>(
    collection,
    {
      locale: locale as StrapiLocale,
      populate: 'deep',
    }
  );

  return result.data.map((page) => page.attributes);
};

export const fetchOne = async <T extends CollectionType>(
  collection: T,
  {
    locale,
    uid,
    strapiClient,
    preview,
  }: {
    locale: string;
    uid: string;
    strapiClient?: Strapi;
    preview?: boolean;
  }
): Promise<GetValues<GetResponseType<T>> | null> => {
  const strapi = strapiClient ?? createStrapiClient();

  const { data } = await strapi.find<APIResponseData<GetResponseType<T>>[]>(
    collection,
    {
      locale: locale as StrapiLocale,
      populate: 'deep',
      filters: {
        uid: {
          $eq: `${locale}${PRISMIC_UID_SEPARATOR}${uid}`,
        },
      },
      publicationState: preview ? 'preview' : 'live',
    }
  );

  if (data.length > 1) {
    throw Error('Too many results');
  } else if (data.length === 0) {
    return null;
  }

  return data[0].attributes;
};

export const getPathSegmentsFromPageDoc = (
  page: GetValues<'api::page.page'>
) => {
  if (!page.uid) return [];

  const segments = page.uid.split(STRAPI_UID_SEPARATOR).slice(1);

  return segments;
};

const isNestedPath = (uid: string) => uid.includes(STRAPI_UID_SEPARATOR);

export const getUidWithoutLocale = (uid: string): string => {
  const [localeSegment, ...otherSegments] = uid.split(STRAPI_UID_SEPARATOR);

  if (!STRAPI_LOCALES.includes(localeSegment)) {
    throw Error("The provided UID didn't contain a valid locale segment");
  }

  if (otherSegments.length === 0) {
    throw Error("The provided UID didn't contain any non-locale segments");
  }

  return otherSegments.join(STRAPI_UID_SEPARATOR);
};

export const isValidUid = (uid: string): boolean => {
  const regex = /^[a-zA-Z0-9-]+$/;
  return regex.test(uid);
};

export const getPathFromUidWithoutLocale = (uid: string) => {
  const pathName = isNestedPath(uid)
    ? uid.replaceAll(STRAPI_UID_SEPARATOR, '/')
    : uid;

  return pathName;
};
