const Sentry = require('@sentry/nextjs');
const locales = require('./locales');
const { isDevelopment } = require('./util/env');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'en-de',
    locales,
    localeDetection: false,
  },
  saveMissing: true,
  serializeConfig: false,
  missingKeyHandler: (_lng, _ns, key) => {
    if (isDevelopment) {
      throw new Error(`[Translation] Missing key: ${key}`);
    } else {
      Sentry.captureMessage(`[Translation] Missing key: ${key}`, 'error');
    }
  },
};
