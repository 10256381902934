import { FilledLinkToDocumentField, PrismicDocument } from '@prismicio/types';
import { PRISMIC_UID_SEPARATOR } from 'constants/prismic';

// this is a workaround for nested path as it is not supported by Prismic
// e.g. Prismic document with uid 'health-insurance--public' gets routed to page health-insurance/public

const isNestedPath = (uid: string) => uid.includes(PRISMIC_UID_SEPARATOR);

// Returns uid to query Prismic document
export const getUidFromPathSegments = (uidArr: string[]) =>
  uidArr.join(PRISMIC_UID_SEPARATOR);

export const getPathSegmentsFromPageDoc = (page: PrismicDocument) => {
  if (!page.uid) return [];

  const segments = page.uid.split(PRISMIC_UID_SEPARATOR);

  return segments;
};

export const getPathFromPageDoc = (
  page: PrismicDocument | FilledLinkToDocumentField
) => {
  if (!page.uid) return '';

  const pathName = parseNestedPath(page.uid);

  return `/${pathName}`;
};

export const parseNestedPath = (uid: string) =>
  isNestedPath(uid) ? uid.replaceAll(PRISMIC_UID_SEPARATOR, '/') : uid;
