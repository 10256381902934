import type { ExtendedVerticalType, VerticalType } from 'models/product';

import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
  LOCALES,
} from './i18n';

interface Link {
  path: string;
  supportedLocales: Locale[];
}

// for policy link section
export const productLinks: Record<VerticalType | ExtendedVerticalType, string> =
  {
    PUBLIC_HEALTH: '/health-insurance/public',
    EXPAT_HEALTH: '/health-insurance/expat/short-term',
    PRIVATE_HEALTH: '/health-insurance/private',
    DENTAL: '/dental-insurance',
    LIABILITY: '/personal-liability-insurance',
    HOUSEHOLD: '/household-insurance',
    LEGAL: '/legal-insurance',
    LIFE: '/life-insurance',
    BIKE: '/bike-insurance',
    DISABILITY: '/disability-insurance',
    TRAVEL: '/travel-health-insurance',
    DOG_LIABILITY: '/dog-liability-insurance',
    COMPANY_HEALTH: '/business/health-insurance',
    COMPANY_PENSION: '/business/pension-insurance',
    COMPANY_TRAVEL: '/business/travel-insurance',
    COMPANY_DISABILITY: '/business/disability-insurance',
    COMPANY_LIFE: '/business/life-insurance',
    PET_HEALTH: '/pet-health-insurance',
    PENSION: '/pension-insurance',
  };

type NonProduct =
  | 'CAREER'
  | 'ABOUT_US'
  | 'BECOME_A_PARTNER'
  | 'SUPPORT'
  | 'FEATHER_IN_DEPTH'
  | 'BUSINESS'
  | 'PRESS';

// config for which locale to show/hide in nav, footer, sitemap
export const nonProductLinks: Record<NonProduct, Link> = {
  CAREER: { path: '/careers', supportedLocales: LOCALES },
  ABOUT_US: {
    path: '/about-us',
    supportedLocales: LOCALES,
  },
  SUPPORT: {
    path: '/support',
    supportedLocales: LOCALES,
  },
  FEATHER_IN_DEPTH: {
    path: '/feather-in-depth',
    supportedLocales: [ENGLISH_LOCALE_IDENTIFIER],
  },
  PRESS: {
    path: '/press',
    supportedLocales: [ENGLISH_LOCALE_IDENTIFIER],
  },
  BECOME_A_PARTNER: {
    path: '/become-a-partner',
    supportedLocales: [
      GERMAN_LOCALE_IDENTIFIER,
      ENGLISH_LOCALE_IDENTIFIER,
      'en-es',
    ],
  },
  BUSINESS: { path: '/business', supportedLocales: [] },
};
