export type Locale = 'en-de' | 'de-de' | 'en-es' | 'es-es' | 'en-fr' | 'fr-fr';

export const ENGLISH_LOCALE_IDENTIFIER: Locale = 'en-de';
export const GERMAN_LOCALE_IDENTIFIER: Locale = 'de-de';

// Locales supported to signup on app
export const FULLY_SUPPORTED_LOCALES = [
  'en-de',
  'de-de',
  'en-es',
  'es-es',
  'en-fr',
  'fr-fr',
];

export const STRAPI_LOCALES = Array.from(
  new Set([
    ...FULLY_SUPPORTED_LOCALES,
    'en-at',
    'en-be',
    'en-it',
    'en-mt',
    'en-nl',
    'en-se',
    'en-pt',
  ])
);

export const PRISMIC_LOCALES = FULLY_SUPPORTED_LOCALES;

// should be same list as locales.js
export const LOCALES = Array.from(
  new Set([...PRISMIC_LOCALES, ...STRAPI_LOCALES])
);
