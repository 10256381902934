import { DateField } from '@prismicio/types';
import { useEffect, useState } from 'react';

const useDate = (initialDate?: DateField) => {
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (initialDate) {
      setDate(new Date(initialDate));
    } else {
      setDate(new Date());
    }
  }, [initialDate]);

  return date;
};

export default useDate;
